require('./bootstrap');

let imagesLoaded = require('imagesloaded');

let windowScrollPosition = $(window).scrollTop(), lazyAnimationPosition = 0;

$(function () {

    /* Smooth Scroll to Position */
    let $root = $('html, body');
    $('.scrollto').on('click', function () {

        let target = $(this).data('scrollto');
        let offSet = 0;
        $root.animate({
            scrollTop: $('[name="' + target + '"]').offset().top - offSet
        }, 800);
        return false;
    });

    let hash = document.URL.substr(document.URL.indexOf('#') + 1);
    if (hash && $('[name="' + hash + '"]').length > 0 ) {
        $root.animate({
            scrollTop: $('[name="' + hash + '"]').offset().top
        }, 800);
        return false;
    }

    //if (windowScrollPosition > 0) {
        init_lazy_animation();
    //}
});

$(window).on('scroll', function () {
    windowScrollPosition = $(window).scrollTop();
    init_lazy_animation();
});

function init_lazy_animation() {
    $('.lazy_animation').each(function () {
        lazyAnimationPosition = $(this).offset().top;
        if (windowScrollPosition + 800 >= lazyAnimationPosition) {
            let type = $(this).data('animate');
            $(this).animateCss(type);

            let type2 = $(this).find('div[data-animate]').data('animate');
            if (type2 != '') {
                $(this).find('div[data-animate]').animateCss(type2);
            }
        }
    });
}


$.fn.extend({
    animateCss: function (animationName, callback) {
        let animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        this.addClass('animated ' + animationName).one(animationEnd, function () {
            //$(this).removeClass('animated ' + animationName);
            if (callback) {
                callback();
            }
        });
        return this;
    }
});
